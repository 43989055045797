<template>
    <div>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
import { auth_list } from '@/network/auth'

export default {
    name:'MainView',
    data(){
        return {}
    },
    computed:{},
    methods:{
        get_auth_list() {
            this.$store.commit('true_loading')
            auth_list()
                .then((s) => {
                    if (s.status === 0) {
                        this.$store.commit('edit_auth_list', s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
                .finally(() => this.$store.commit('false_loading'))

        }
    },
    filters:{},
    props:{},
    created(){
        this.get_auth_list()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>